<template>
  <v-container class="px-lg-12">
    <h2 class="text-h4 mb-6">Dashboard</h2>
    <status-alert v-if="copySuccess" type="success">Copied to Clipboard</status-alert>

    <!-- Header -->
    <div class="d-flex justify-space-between align-center">
      <h3>Permanent Installs Overview</h3>
      <v-btn text :loading="reportNotLoaded" @click="copyReportToClipboard">
        <v-icon left>mdi-content-copy</v-icon>
        Copy Report
      </v-btn>
    </div>
    <v-divider class="mb-6" />

    <!-- Dashboard Cards -->
    <v-row v-if="deliverables.length">
      <v-col v-for="(deliverable, index) in deliverables" :key="deliverable.id" cols="6" sm="4" md="3">
        <v-card>
          <div class="thumbnail" @click="showPreview(index)">
            <deliverable-thumbnail :deliverable="deliverable" />
            <div class="thumbnail-info d-flex justify-space-between align-center">
              <span class="text-caption"></span>
              <span @click.stop>
                <v-btn dark icon @click="goToContent(deliverable)">
                  <v-icon color="white" style="background-color: #00000090; border-radius: 4px;">mdi-open-in-new</v-icon>
                </v-btn>
              </span>
            </div>
          </div>

          <!-- Card Body -->
          <v-card-text>
            <b style="color: black">{{ deliverable.profile_name || deliverable.profile_id }}</b><br />
            <b>Last Capture: </b>{{ $dayjs(deliverable.sync_time).fromNow() }}<br />

            <!-- Monitoring Chips -->
            <monitoring-chips :profileId="deliverable.profile_id" :profileName="deliverable.profile_name" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Skeleton Loaders -->
    <v-row v-if="reportNotLoaded">
      <v-col v-for="index in placeholderCount" :key="index" cols="6" sm="4" md="3">
        <v-skeleton-loader class="mx-auto" max-width="300" type="card, list-item@2" elevation="2"></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- Deliverable Preview Dialog -->
    <deliverable-preview-dialog
      ref="preview"
      nav
      :previousDisabled="previewItemIndex === 0"
      :nextDisabled="previewItemIndex === deliverables.length - 1"
      @previous="showPreview(previewItemIndex - 1)"
      @next="showPreview(previewItemIndex + 1)"
    />

  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import StatusAlert from '../components/StatusAlert.vue';
import DeliverableThumbnail from '../components/DeliverableThumbnail.vue';
import DeliverablePreviewDialog from '../components/DeliverablePreviewDialog.vue';
import MonitoringChips from '../components/MonitoringChips.vue';

export default {
  name: 'Dashboard',
  components: {
    StatusAlert,
    DeliverableThumbnail,
    DeliverablePreviewDialog,
    MonitoringChips
  },

  data() {
    const formattedDate = `Perm Installs Report ${dayjs().format('dddd, MMMM D, YYYY (h:mmA)')} \n----------------------------------------\n`;
    return {
      deliverables: [],
      report: formattedDate,
      reportNotLoaded: true,
      copySuccess: false,
      previewItemIndex: null,
      placeholderCount: 20,  // Number of skeleton loaders
    };
  },

  async created() {
    let profiles = await this.$root.getProfiles();
    profiles = profiles.filter(profile => profile.id.startsWith('install.'));

    // fetch most recent deliverable from each install
    for (let i = 0; i < profiles.length; i++) {
      await this.loadDeliverable(profiles[i]);
    }
    this.reportNotLoaded = false;
  },

  methods: {
    async loadDeliverable(profile) {
      let profileName = profile.name || null;
      let response = await this.$api.get('/deliverables', {
        params: {
          profile_id: profile.id,
          page_size: 1,
          order_by: 'desc'
        }
      });
      let deliverable = response.data.items[0];
      if (!deliverable) return; // if profile has no deliverable, break.
      if (!deliverable.thumbnail) return; // if deliverable has no content, break.
      deliverable.monitoring = []; // initialize monitoring.
      deliverable.profile_name = profileName;
      this.deliverables.push(deliverable);

      // gathering data for the report
      this.report += `${profile.id}`;
      this.report += ` - Last Capture ${this.$dayjs(deliverable.create_time).fromNow()}\n`; // E.g. 7 Days Ago
    },

    async copyReportToClipboard() {
      try {
        await navigator.clipboard.writeText(this.report);
        this.copySuccess = true;
      } catch (err) {
        this.copySuccess = false;
        console.error('Failed to copy text: ', err);
      }
    },

    showPreview(index) {
      this.previewItemIndex = index;
      this.$refs.preview.open(this.deliverables[index]);
    },

    goToContent(deliverable) {
      this.$root.profileId = deliverable.profile_id;
      this.$router.push({ name: 'content' });
    },
  }
};
</script>

<style scoped>
.thumbnail {
  position: relative;
  width: 100%;
  aspect-ratio: 4 / 6;
  object-fit: cover;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
}

.thumbnail-info {
  position: absolute;
  bottom: 6px;
  width: 100%;
  color: #000;
  background: linear-gradient(rgba(75, 74, 74, 0), rgba(132, 132, 132, 0.43));
  opacity: 0;
  transition: opacity 0.1s;
}

.thumbnail:hover .thumbnail-info {
  opacity: 1;
}
</style>
