<template>
  <div class="mt-1">
    <v-chip v-if="!monitoringLoaded" small outlined :style="{ opacity : !transparent ? '1' : '0' }">
      <v-progress-circular indeterminate width="1" style="zoom: .5" />
    </v-chip>
    <div v-else class="d-flex align-center" @click="showResourcesDialog">
      <!-- 'Online' Chip -->
      <v-tooltip bottom open-delay="250" :color="determineColor">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-chip v-if="numOfOnlineResources" class="ml-1" small link text-color="white" :color="determineColor">
              <b v-if="totalNumOfIssues">{{ totalNumOfIssues }}&nbsp;</b>
              <v-icon size="medium">{{ totalNumOfIssues ? `mdi-alert-circle` : `mdi-check-circle`}}</v-icon>
            </v-chip>
          </div>
        </template>
        <!-- tooltip text box -->
        <div class="text-center">
          <b v-if="!totalNumOfIssues">{{ numOfOnlineResources }} computers online</b>
          <div v-if="totalNumOfIssues">
            <div class="text-decoration-underline font-weight-bold">
              {{ numOfOnlineResources }} computers online
            </div>
            <div class="d-flex align-center" v-if="numOfWarnings"> 
              <v-icon class="mr-1" size="large" color="white">mdi-alert-circle</v-icon>
              {{numOfWarnings}} warnings
            </div>
            <div class="d-flex align-center" v-if="numOfErrors">
              <v-icon class="mr-1" size="large" color="white">mdi-alert-circle</v-icon>
              {{numOfErrors}} errors 
            </div>
          </div>
        </div>
      </v-tooltip>
      <!-- 'Unplugged' Chip -->
      <v-tooltip bottom open-delay="250" color="danger">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-chip v-if="numOfOfflineResources" class="ml-1" small link outlined color="danger">
              <v-icon size="medium">mdi-power-plug-off</v-icon>
            </v-chip>
          </div>
        </template>
        <!-- tooltip text box -->
        <div>
          <b class="text-decoration-underline">{{ numOfOfflineResources }} computers offline:</b>
          <div v-for="(resource, i) in monitoring.filter(resource => !resource.has_recent_event)" :key="i">
            {{ resource.name }} - Last seen {{ $dayjs(resource.latest_event.sync_time).fromNow() }}
          </div>
        </div>
      </v-tooltip>
    </div>

    <!-- Monitor Resource Card Dialog -->
    <v-dialog v-model="resourceDialog" :maxWidth="monitoring?.length > 1 ? 1000 : 600">
      <v-card>
        <v-card-title class="headline">
          {{ profileName || profileId }}
          <v-spacer></v-spacer>
          <v-icon @click="resourceDialog = false" class="cursor-pointer">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col 
              v-for="(resource, index) in monitoring" :key="index" 
              cols="12" :sm="monitoring.length > 1 ? `6`: `12`">
              <monitored-resource-card :resource="resource" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resourceDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MonitoredResourceCard from './MonitoredResourceCard.vue';
import {convertLegacyMetric, isResourceOnline} from '../utils'

export default {
  name: 'MonitoringChips',
  components: {MonitoredResourceCard},
  props: {
    profileId: {
      type: String,
      required: true
    },
    profileName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      monitoring: null,
      monitoringLoaded: false,
      transparent: false,
      resourceDialog: false,
      monitoringTimer: null
    };
  },

  async created() {
    await this.loadProfileMonitoringInfo();
    if (this.monitoring.length) { this.monitoringLoaded = true; } else { this.transparent = true; }
    this.updateMonitoring();
  },

  destroyed() {
    clearTimeout(this.monitoringTimer)
  },

  computed: {
    numOfWarnings() {
      return this.numOfIssues("warning");
    },

    numOfErrors() {
      return this.numOfIssues("error");
    },

    totalNumOfIssues() {
      return this.numOfWarnings + this.numOfErrors;
    },

    numOfOnlineResources() {
      return this.monitoring.filter(isResourceOnline).length;
    },

    numOfOfflineResources() {
      return this.monitoring.length - this.numOfOnlineResources;
    },

    determineColor() {
      return this.numOfErrors ? 'error' : this.numOfWarnings ? 'warning' : 'success';
    },
  },

  methods: {
    async loadProfileMonitoringInfo() {
      try {
        let response = await this.$api.get(`/profiles/${this.profileId}/monitored-resources`, {
          params: {
            page_size: 100
          }
        });
        this.monitoring = response.data.items;
      } catch (error) {
        console.log(error);
        this.monitoring = [];
      }
    },
    
    numOfIssues(issueType) {
      let issues = 0;
      if (this.monitoring) {
        this.monitoring.forEach(resource => {
          issues += this.checkForIssuesOnResource(resource, issueType);
        });
      }
      return issues;
    },
    
    checkForIssuesOnResource(resource, issueType) {
      if (!isResourceOnline(resource)) return 0;
      let issues = 0;
      let metrics = resource.latest_event.metrics;
      for (let i = 0; i < metrics.length; i++) {
        if (convertLegacyMetric(metrics[i]).status === issueType) issues++;
      }
      return issues;
    },

    showResourcesDialog() {
      this.resourceDialog = true;
    },

    async updateMonitoring() {
      await this.loadProfileMonitoringInfo();
      this.monitoringTimer = setTimeout(() => {
        this.updateMonitoring();
      }, 120000); // update every 2 minutes
    }
  },
};
</script>
